<template>
  <VueFinalModal
    v-model="isOpen"
    v-bind="$attrs"
    content-class="pointer-events-none fixed inset-0 flex w-full justify-end sm:left-auto md:bottom-auto md:left-1/2 md:right-auto md:top-1/2 md:size-auto md:-translate-x-1/2 md:-translate-y-1/2 md:items-center md:justify-center"
    :z-index-base="100000"
    esc-to-close
  >
    <div class="pointer-events-auto md:flex md:items-center md:justify-end">
      <div
        class="relative flex max-h-window-height min-h-full w-full flex-col overflow-auto bg-background-dark sm:w-[360px] md:min-h-[400px] md:w-[800px] md:flex-row md:rounded-3xl"
      >
        <template v-if="!isSuccess">
          <div class="relative flex-col justify-center px-5 pb-8 pt-10 md:flex md:basis-1/2 md:px-10 md:py-16">
            <OnImg v-if="props.image" class="mb-6 h-auto w-full" :src="props.image.src" :alt="props.image.alt" sizes="sm:100% lg:560px" />
            <h2 class="text-center text-h2 md:text-left">{{ t('w.module.newsletter_form_modal.title') }}</h2>
            <p class="mt-4 text-center text-lg md:text-left">{{ t('w.module.newsletter_form_modal.text') }}</p>
          </div>
          <div class="md:relative md:basis-1/2">
            <button
              class="absolute right-3 top-3 flex size-10 items-center justify-center rounded-full bg-background transition-colors hover:bg-background-none-hover active:bg-background-none-active md:bg-background-dark"
              @click="isOpen = false"
            >
              <span class="sr-only">{{ t('w.modal.close') }}</span>
              <Icon name="close" class="size-6" />
            </button>
            <div class="flex h-full flex-col justify-center px-5 pb-10 md:py-16 md:pl-6 md:pr-8">
              <LoginFacebook is-large class="py-2" @successful-login="isSuccess = true" />
              <LoginGoogle v-if="false" is-large class="mt-2 py-2" @successful-login="isSuccess = true" />
              <div class="relative flex justify-center py-4 before:absolute before:inset-x-0 before:mt-2 before:h-px before:bg-border-medium">
                <span class="relative inline-block bg-background-dark px-5 text-base">{{ t('w.module.newsletter_form_modal.divider_text') }}</span>
              </div>
              <form @submit.prevent="submitForm">
                <Input
                  v-model="emailValue"
                  :is-error="isEmailError"
                  :label="t('module.newsletter_form_modal.email')"
                  type="email"
                  class="grow"
                  name="newsletter-form-email"
                  autocomplete="email"
                  :placeholder="t('w.module.newsletter_form_modal.email_placeholder')"
                  hide-label
                />
                <Input
                  v-model="passwordValue"
                  :is-error="isPasswordError"
                  :label="t('module.newsletter_form_modal.password')"
                  type="password"
                  name="newsletter-form-password"
                  class="mt-2 grow"
                  autocomplete="current-password"
                  :placeholder="t('w.module.newsletter_form_modal.password_placeholder')"
                  hide-label
                />
                <CheckboxInput v-model="agreementValue" :is-error="isAgreementError" class="my-4 text-base" :label="t('w.module.newsletter_form_modal.checkbox_text')">
                  <span class="newsletter-form-modal__checkbox-text" v-html="t('w.module.newsletter_form_modal.checkbox_text')" />
                </CheckboxInput>
                <Button class="btn-rounded w-full py-3" icon-name="arrow-right" :is-loading="isLoading" @click="submitForm">
                  {{ t('w.module.newsletter_form_modal.button_text') }}
                </Button>
              </form>
            </div>
          </div>
        </template>
        <template v-else>
          <button
            class="absolute right-3 top-3 flex size-10 items-center justify-center rounded-full bg-background-dark transition-colors hover:bg-background-none-hover active:bg-background-none-active"
            @click="isOpen = false"
          >
            <span class="sr-only">{{ t('w.modal.close') }}</span>
            <Icon name="close" class="size-6" />
          </button>
          <div class="flex size-full flex-col justify-center px-10 py-16 text-center">
            <OnImg v-if="props.imageSuccess" class="mb-6 h-auto w-full md:px-[200px]" :src="props.imageSuccess.src" :alt="props.imageSuccess.alt" sizes="sm:400px lg:500px" />
            <h1 class="text-h2">{{ t('w.module.newsletter_form_modal.success_title') }}</h1>
            <p class="mt-4 text-lg">{{ t('w.module.newsletter_form_modal.success_text') }}</p>
          </div>
        </template>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { useTranslate } from '~/composables/translate'
import { VueFinalModal } from 'vue-final-modal'

import LoginGoogle from '~/components/login/buttons/LoginGoogle.vue'
import LoginFacebook from '~/components/login/buttons/LoginFacebook.vue'
import CheckboxInput from '~/components/input/CheckboxInput.vue'
import OnImg from '~/components/on-img/OnImg.vue'

const props = withDefaults(
  defineProps<{
    image?: {
      src: string
      alt: string
    } | null
    imageSuccess?: {
      src: string
      alt: string
    } | null
    appearTime?: number
  }>(),
  {
    image: null,
    imageSuccess: null,
    appearTime: 6000,
  }
)

const { t } = useTranslate()

const isOpen = ref(false)
const isLoading = ref(false)
const isSuccess = ref(false)

const isAgreementError = ref(false)
const isEmailError = ref(false)
const isPasswordError = ref(false)

const emailValue = ref('')
const passwordValue = ref('')
const agreementValue = ref(false)

const open = () => {
  isOpen.value = true
}

defineExpose({
  open,
})

const submitForm = async () => {
  const isEmailEmpty = emailValue.value === ''
  const isInvalidEmail = !emailValue.value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
  const isPasswordEmpty = passwordValue.value === ''
  const isAgreementEmpty = !agreementValue.value

  if (isEmailEmpty || isPasswordEmpty || isAgreementEmpty || isInvalidEmail) {
    isAgreementError.value = isEmailEmpty
    isEmailError.value = isPasswordEmpty || isInvalidEmail
    isPasswordError.value = isAgreementEmpty
  } else {
    new Promise((resolve, reject) => {
      isLoading.value = true
      setTimeout(() => {
        isSuccess.value = false
        isAgreementError.value = false
        isEmailError.value = false
        isPasswordError.value = false
        isLoading.value = false
        if (emailValue.value !== '' && passwordValue.value !== '' && agreementValue.value) {
          resolve()
        } else {
          reject()
        }
      }, 1000)
    }).then(
      () => {
        isSuccess.value = true
      },
      () => {
        isAgreementError.value = true
        isEmailError.value = true
        isPasswordError.value = true
      }
    )
  }
}
</script>

<style lang="scss">
.newsletter-form-modal__checkbox-text {
  a {
    text-decoration: underline !important;
  }
}
</style>
